import Header from "@/features/user/Header";
import Navigation from "@/features/common/navigation";

import Topic from "@/features/common/topicInfo";
import RegulatorList from "@/features/address/regulatoryList";
import WalletList from "@/features/address/walletList";
import RiskAssessment from "@/features/address/riskAssessment";
import ValidationRule from "@/features/address/validationRules";
import { t } from "i18next";
import "./Entity.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  initEntity,
  selectEntityInfo,
  selectLoading,
  showHackDetail,
} from "@/features/entity/entitySlice";
import EntityImg from "@/assets/entity.png";
import { useEffect } from "react";

function Index() {
  const { name, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const entityInfo = useAppSelector(selectEntityInfo);
  const loading = useAppSelector(selectLoading);

  useEffect(() => {
    if (!loading && id) {
      dispatch(initEntity(parseInt(id)));
    }
  });

  if (!id || !name) return navigate("/");

  document.title = `Entity ${name}`;

  return (
    <>
      <Header />
      <div className="body-container">
        <Navigation id={name} />

        <Topic
          title={t("entities.title")}
          icon={EntityImg}
          name={name}
          tagList={loading ? [] : entityInfo?.tags}
        />

        <RegulatorList />

        {entityInfo && showHackDetail(entityInfo.type) ? (
          <div className="risk-info-wrap">
            <div className="info-item">
              <RiskAssessment />
            </div>
            <div className="info-item">
              <ValidationRule />
            </div>
          </div>
        ) : null}

        <WalletList />
      </div>
    </>
  );
}

export default Index;
