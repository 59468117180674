import { AppThunk, RootState } from "@/app/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18next";
import { clearInitParams as clearTransactionParams } from "../transaction/transactionSlice";
import {
  Analysis,
  AnalysisType,
  ChoosMetaData,
  Coin,
  GetAddressInfo,
  GetMetadatas,
  GetScoringAnalysis,
  GetTransactions,
  GetWallets,
  InfoData,
  Metadata,
  ObjectType,
  ScoringAnalysisData,
  Transaction,
  WalletRes,
  BlockChains,
  GetBlockChainData,
  WalletData,
} from "./addressAPI";
type T = /*unresolved*/ any
export interface AddressSliceIFace {
  blockchains: BlockChains | null;
  loadingBlockChains: boolean;

  loading: boolean;
  InfoData: InfoData | null;
  ScorigingAnalysisData: ScoringAnalysisData | null;
  ScorigingAnalysisError: string | null;
  loadScorigingAnalysis: boolean;
  AnalysisList: Array<Analysis>;
  Metadata: Metadata | null;
  choosMetadata: ChoosMetaData | null;
  Coins: Array<Coin> | null;
  AssetNav: Array<Coin> | null;
  Transactions: Array<Transaction> | null;
  loadingTransaction: boolean;
  TransactionCount: number;
  analysisType: AnalysisType;

  WalletData: WalletRes |null;
  loadWalletData: boolean;

  showAlert: boolean;

  initParams: {
    hash: string;
    blockchain: string;
    wallet: boolean;
  };

  // 单位
  symbol: string;
}

const initialState: AddressSliceIFace = {
  blockchains: null,
  loadingBlockChains: false,

  loading: false,
  InfoData: null,
  Metadata: null,
  choosMetadata: null,

  ScorigingAnalysisData: null,
  ScorigingAnalysisError: null,
  loadScorigingAnalysis: false,

  Coins: null,
  AssetNav: null,

  Transactions: null,
  TransactionCount: 0,
  loadingTransaction: false,

  AnalysisList: [],
  analysisType: AnalysisType.Incoming,

  WalletData: null,
  loadWalletData: false,

  showAlert: false,

  initParams: {
    hash: "",
    blockchain: "",
    wallet: false,
  },

  symbol: "",
};

export interface initParams {
  hash: string;
  blockchain: string;
  analysisType: AnalysisType;
  wallet?: boolean;
}

export const init = createAsyncThunk(
  "address/init",
  async (params: initParams) => {
    const AddressInfo = await GetAddressInfo({
      hash: params.hash,
      blockchain: params.blockchain,
      type: params.wallet ? "wallets" : "addresses",
    });

    return AddressInfo;
  }
);

export const GetAnalysisData = createAsyncThunk(
  "address/getAnalysisData",
  async (params: {
    analysisType: AnalysisType;
    objectType: ObjectType;
    objectId: string;
    blockchain: string;
    coin?: string;
  }) => {
    const ScorigingAnalysisData = await GetScoringAnalysis({
      analysisType: params.analysisType,
      objectType: params.objectType,
      objectId: params.objectId,
      blockchain: params.blockchain,
      coin: params.coin ? params.coin : "ALL",
      // "depth": 100
    });

    return ScorigingAnalysisData;
  }
);

export const GetMetadata = createAsyncThunk(
  "address/getMetadata",
  async (params: { address: string; blockchain: string; type: string }) => {
    const Metadata = await GetMetadatas(params);
    return {
      data: Metadata,
      blockchain: params.blockchain,
    };
  }
);

export const GetTransaction = createAsyncThunk(
  "address/getTransaction",
  async (params: {
    blockchain: string;
    type: string;
    address: string;
    offset: number;
    limit: number;
  }) => {
    const TransactionData = await GetTransactions(params);
    return TransactionData;
  }
);

export const CalcAssetsScore = createAsyncThunk(
  "address/CalcAssetsScore",
  async (params: {
    analysisType: AnalysisType;
    blockchain: string;
    objectId: string;
    coin: string;
    index: number;
  }) => {
    const ScorigingAnalysisData = await GetScoringAnalysis({
      analysisType: params.analysisType,
      blockchain: params.blockchain,
      objectType: ObjectType.Address,
      objectId: params.objectId,
      coin: params.coin,
    });
    return {
      index: params.index,
      type: params.analysisType,
      score: ScorigingAnalysisData.score,
    };
  }
);

export const GetWallet = createAsyncThunk(
  "address/GetWallet",
  async (params: {
    blockchain: string;
    chainId: string;
    offset: number;
    limit: number;
  }) => {
    const wallets = await GetWallets(params);
    return wallets;
  }
);

export const GetBlockChain = createAsyncThunk(
  "address/GetBlockChain",
  async () => {
    const blockchains = await GetBlockChainData();
    return blockchains;
  }
);

export const AddressSlice = createSlice({
  name: "address",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (action.payload) {
        state.symbol = "";
      }
    },
    setLoadingBlockChain: (state, action: PayloadAction<boolean>) => {
      state.loadingBlockChains = action.payload;
    },
    reverseCoins: (state) => {
      state.Coins?.reverse();
    },
    reverseAnalysisData: (state) => {
      state.AnalysisList.reverse();
    },
    toogleAlert: (state, action: PayloadAction<boolean>) => {
      state.showAlert = action.payload;
    },
    setInitParams: (state, action: PayloadAction<initParams>) => {
      state.initParams = {
        blockchain: action.payload.blockchain,
        hash: action.payload.hash,
        wallet: action.payload.wallet || false,
      };
    },
    setCalcScoreStatus: (
      state,
      action: PayloadAction<{ type: number; index: number; status: boolean }>
    ) => {
      // state.Coins[action.payload.index].calcIncomeScore = false
      if (!state.Coins) return;
      const { type, index, status } = action.payload;
      if (type === 0) {
        state.Coins[index].calcIncomeScore = status;
      } else {
        state.Coins[index].calcSpendingScore = status;
      }
    },
    // 清空数据
    clearInitParams: (state) => {
      state.initParams.blockchain = "";
    },

    // 更新资产模块
    changeChoosMetadata: (
      state,
      action: PayloadAction<{ blockchain: string; index: number }>
    ) => {
      const metadata = state.Metadata;
      const coins = state.Coins;
      if (!metadata || !coins) return;

      let choosMetadata: ChoosMetaData = {
        balanceUsd: metadata.BalanceUsd,
        amountReceivedUsd: metadata.AmountReceivedUsd,
        amountSentUsd: metadata.AmountSentUsd,
        numberOfTransactions: metadata.NumberOfTransactions,
      };
      // 没有资产的情况下获取一个数据
      const assets = showAssets(action.payload.blockchain);
      if (!assets || (assets && action.payload.index > 0)) {
        // 如果是选择资产，则显示指定资产，否则默认拿第0个
        const coin =
          coins[action.payload.index > 0 ? action.payload.index - 1 : 0];

        choosMetadata.amountReceived = coin.amountReceived;
        choosMetadata.balance = coin.balance;
        choosMetadata.amountSent = coin.amountSent;
        choosMetadata.symbol = coin.symbol;
        choosMetadata.balanceUsd = coin.balanceUsd;
        choosMetadata.amountReceivedUsd = coin.amountReceivedUsd;
        choosMetadata.amountSentUsd = coin.amountSentUsd;
        choosMetadata.numberOfTransactions = coin.numberOfTransactions;
        state.symbol = coin.symbol;
      }

      state.choosMetadata = choosMetadata;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(init.fulfilled, (state, action) => {
        state.InfoData = action.payload;
        state.loading = false;
      })
      .addCase(GetAnalysisData.pending, (state, action) => {
        state.loadScorigingAnalysis = true;
      })
      .addCase(GetAnalysisData.fulfilled, (state, action) => {
        const data = action.payload;
        state.ScorigingAnalysisData = data;
        state.analysisType = data.analysisType as AnalysisType;
        state.AnalysisList = [...data.details];
        state.loadScorigingAnalysis = false;
        state.ScorigingAnalysisError = null;
      })
      .addCase(GetAnalysisData.rejected, (state, action) => {
        state.loadScorigingAnalysis = false;
        state.AnalysisList = [];
        state.ScorigingAnalysisError = action.error.message as string;
        state.ScorigingAnalysisData = null;
      })
      .addCase(GetMetadata.fulfilled, (state, action) => {
        const { metadata, coins } = action.payload.data;
        state.Metadata = metadata;
        state.Coins = coins;
        state.AssetNav = [...coins];
      })   
      .addCase(GetMetadata.pending, (state, action) => {
        state.Metadata = null;
        state.choosMetadata = null;
        state.Coins = [];
        state.AssetNav = [];
      })
      .addCase(GetTransaction.pending, (state, action) => {
        state.loadingTransaction = true;
      })
      .addCase(GetTransaction.fulfilled, (state, action) => {
        state.Transactions = action.payload.data;
        state.TransactionCount = action.payload.total;
        state.loadingTransaction = false;
      })
      .addCase(GetTransaction.rejected, (state) => {
        state.loadingTransaction = false;
        state.Transactions=[]
        state.TransactionCount =0
      })

      .addCase(CalcAssetsScore.fulfilled, (state, action) => {
        if (state.Coins === null) return;
        if (action.payload.type === AnalysisType.Incoming) {
          state.Coins[action.payload.index].incomeScore = action.payload.score;
          state.Coins[action.payload.index].calcIncomeScore = false;
        } else {
          state.Coins[action.payload.index].spendingScore =
            action.payload.score;
          state.Coins[action.payload.index].calcSpendingScore = false;
        }
      })

      .addCase(GetWallet.pending, (state, action) => {
        state.loadWalletData = true;
      })
      .addCase(GetWallet.fulfilled, (state, action) => {
        state.WalletData = action.payload;
        state.loadWalletData = false;
      })
      .addCase(GetWallet.rejected, (state) => {
        state.loadWalletData = false;
      })

      .addCase(GetBlockChain.fulfilled, (state, action) => {
        state.blockchains = action.payload;
      });
  },
});

export const initAddress =
  (params: initParams): AppThunk =>
  async (dispatch, getState) => {
    const loading = selectLoading(getState());
    const initPrams = selectInitPrams(getState());

    // 清空交易页面数据，避免数据覆盖
    dispatch(clearTransactionParams());

    if (
      !loading &&
      (initPrams.blockchain !== params.blockchain ||
        initPrams.hash !== params.hash ||
        initPrams.wallet !== params.wallet)
    ) {
      dispatch(setLoading(true));

      // 初始化数据
      dispatch(setInitParams(params));

      dispatch(init(params));

      dispatch(
        GetAnalysisData({
          analysisType: AnalysisType.Incoming,
          objectType: params.wallet ? ObjectType.Wallet : ObjectType.Address,
          objectId: params.hash,
          blockchain: params.blockchain,
          coin: showAssets(params.blockchain) ? "ALL" : "MAIN",
        })
      );

      dispatch(
        GetMetadata({
          address: params.hash,
          blockchain: params.blockchain,
          type: params.wallet ? "wallets" : "addresses",
        })
      ).then(() => {
        dispatch(
          changeChoosMetadata({ blockchain: params.blockchain, index: -1 })
        );
      });

      dispatch(
        GetTransaction({
          blockchain: params.blockchain,
          address: params.hash,
          offset: 0,
          type: params.wallet ? "wallets" : "addresses",
          limit: 50,
        })
      );

      if (params.wallet) {
        dispatch(
          GetWallet({
            blockchain: params.blockchain,
            chainId: params.hash,
            offset: 0,
            limit: 50,
          })
        );
      }
    }
  };

// 初始化币种数据
export const initBlockChain = (): AppThunk => async (dispatch, getState) => {
  const state = getState();
  if (!state.address.loadingBlockChains) {
    dispatch(setLoadingBlockChain(true));
    dispatch(GetBlockChain());
  }
};

export const selectAddressInfo = (state: RootState) => state.address.InfoData;
export const selectLoading = (state: RootState) => state.address.loading;
export const selectAnalysisType = (state: RootState) =>
  state.address.analysisType;
export const selectAnalysisData = (state: RootState) =>
  state.address.ScorigingAnalysisData;
export const selectLoadScorigingAnalysis = (state: RootState) =>
  state.address.loadScorigingAnalysis;
export const selectAnalysisError = (state: RootState) =>
  state.address.ScorigingAnalysisError;

export const selectAnalysisList = (state: RootState) =>
  state.address.AnalysisList;
export const selectMetadata = (state: RootState) => state.address.Metadata;
export const selectChoosMetadata = (state: RootState) =>
  state.address.choosMetadata;
export const selectCoins = (state: RootState) => state.address.Coins;
// 资产列表菜单
export const selectAssetNav = (state: RootState) => state.address.AssetNav;
export const selectTransaction = (state: RootState) => ({
  data: state.address.Transactions||[],
  count: state.address.TransactionCount,
});
export const selectLoadingTransaction = (state: RootState) =>
  state.address.loadingTransaction;

export const selectAlertWindow = (state: RootState) => state.address.showAlert;

export const selectWalletData = (state: RootState) => state.address.WalletData;
export const selectLoadWalletData = (state: RootState) =>
  state.address.loadWalletData;

// 获取所有币种数据
export const selectBlockChain = (state: RootState) => state.address.blockchains;

export const selectInitPrams = (state: RootState) => state.address.initParams;

export enum Coins {
  bitcoin = "BITCOIN",
  bitcoincash = "BITCOINCASH",
  litecoin = "LITECOIN",
  dash = "DASH",
  ethereum = "ETHEREUM",
  ripple = "RIPPLE",
  tezos = "TEZOS",
  tron = "TRON",
  bsc = "BSC",
}

export const CoinsAssetsList = [Coins.ethereum, Coins.tron, Coins.bsc];

// 有钱包数据的虚拟币列表
export const HasWalletCoinsList = [
  Coins.bitcoin,
  Coins.bitcoincash,
  Coins.litecoin,
  Coins.dash,
];

// 是否有资产判断
export function showAssets(blockchain: string): boolean {
  return CoinsAssetsList.indexOf(blockchain as Coins) !== -1;
}
// 是否有钱包判断
export function hasWallet(blockchain: string): boolean {
  return HasWalletCoinsList.indexOf(blockchain as Coins) !== -1;
}

export enum AddressType {
  Address = 1,
  Wallet = 2,
}

// 将 blockchain 转成中文
export function blockchainTranslate(blockchain: string): string {
  // if (blockchain.toLowerCase() in Coins)
  //   return t("coins." + blockchain.toUpperCase());
  return blockchain;
}

// 获取单位
export const selectSymbol = (state: RootState) => state.address.symbol;

export const {
  setLoading,
  reverseCoins,
  reverseAnalysisData,
  toogleAlert,
  setInitParams,
  setCalcScoreStatus,
  clearInitParams,
  changeChoosMetadata,
  setLoadingBlockChain,
} = AddressSlice.actions;

export default AddressSlice.reducer;
