import styles from "./user.module.css";
import { store } from "@/app/store";
import logoBlack from "@/assets/logoBlack.png";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { initNotice, selectNotice } from "../alert/alertSlice";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { EventCallback, removeClickEvent, setClickEvent } from "@/app/util";
import { logout } from "./Auth";
import { logout as loginLogout } from "./userSlice";
import { initBlockChain, selectBlockChain } from "../address/addressSlice";
import { Popover } from "antd";

import headerStyles from "./header.module.css";
import { ReactSVG } from "react-svg";
import manageSearchIcon from "@/assets/header/manage_search.svg";
import listAltIcon from "@/assets/header/notifications_active.svg";
import notificationsActiveIcon from "@/assets/header/list_alt.svg";
let listenClickEvent: EventCallback;

export default function Header() {
  const userState = store.getState().user;

  const notices = useAppSelector(selectNotice);
  const blockchains = useAppSelector(selectBlockChain);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showWin, setShowWin] = useState(false);
  const [logined, setLogin] = useState(true);
  const [showMonitor, setShowMonitor] = useState(false);
  useEffect(() => {
    if (!logined) {
      setLogin(true);
      navigate(`/login`);
    }

    if (!notices) {
      dispatch(initNotice());
    }

    if (!blockchains) {
      dispatch(initBlockChain());
    }
  }, [logined, notices, navigate, dispatch]);
  const rederSvg = (str: string) => {
    return (
      <ReactSVG
        className={headerStyles.PopoverItemImge}
        beforeInjection={(svg) => {
          svg.setAttribute("style", `width: 24px;height: 24px;`);
        }}
        desc="Description"
        evalScripts="always"
        src={str}
      />
    );
  };
  const content1 = (
    <div className={headerStyles.PopoverContainer}>
      <div
        className={headerStyles.PopoverItem}
        onClick={() => {
          navigate(`/`);
        }}
      >
        {rederSvg(manageSearchIcon)}
        Search
      </div>
    </div>
  );
  const content2 = (
    <div className={headerStyles.PopoverContainer}>
      <div
        className={headerStyles.PopoverItem}
        onClick={() => {
          navigate(`/notificationsList`);
        }}
      >
        {rederSvg(notificationsActiveIcon)}
        Alert Notifications
      </div>
      <div
        className={headerStyles.PopoverItem}
        onClick={() => {
          navigate(`/myList`);
        }}
      >
        {rederSvg(listAltIcon)}
        My List
      </div>
    </div>
  );
  return (
    <div className={styles.header}>
      <div className={styles["header-block"]}>
        <img className={styles["header-logo"]} src={logoBlack} alt="" />
        <div className={styles["header-controls"]}>
          <Popover
            content={content1}
            title=""
            trigger="hover"
            className={styles["header-margin"]}
          >
            Analyse
          </Popover>
          {showMonitor && (
            <Popover
              content={content2}
              title=""
              trigger="hover"
              className={styles["header-margin"]}
            >
              Monitor
            </Popover>
          )}

          <div className={styles["header-divider"]}></div>
          <img
            onClick={() => {
              setShowWin(true);
              removeClickEvent(listenClickEvent);
              listenClickEvent = () => {
                setShowWin(false);
                removeClickEvent(listenClickEvent);
              };
              setClickEvent(listenClickEvent);
            }}
            className={styles["header-avatar"]}
            src={userState.avatar}
            alt=""
          />
          {/* <p
            className={styles["header-help"]}
            onClick={() => {
              window.open(
                "https://www.falconchaincheck.com/FCC-User-Manual.pdf"
              );
            }}
          >
            {t("help.download")}
          </p> */}
          <nav
            style={{ display: showWin !== false ? "block" : "none" }}
            className={styles["avatar-win"]}
          >
            <li
              className={styles["align-left"]}
              title={userState.caseId.toString() || ""}
              onClick={() => {}}
            >
              {t("help.case")}
              {userState.caseId}
            </li>
            <li className={styles["align-left"]}>
              {t("help.email") + userState.email}
            </li>

            <li
              onClick={() => {
                dispatch(loginLogout());
                dispatch(logout());
                setTimeout(() => {
                  setLogin(false);
                });
              }}
            >
              {t("login.logout")}
            </li>
          </nav>
        </div>
      </div>
    </div>
  );
}
